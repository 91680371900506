import React from 'react';

const siteRoutes = [
  {
    key: 'homepage',
    path: `/`,
    component: React.lazy(() => import('views/website/HomePage')),
    authority: [],
  },
  {
    key: 'privacy-police',
    path: `/politicas-de-privacidade`,
    component: React.lazy(() => import('views/website/PrivacyPolice')),
    authority: [],
  },
  {
    key: 'services',
    path: `/results/:id`,
    component: React.lazy(() => import('views/website/Results')),
    authority: [],
  },
  {
    key: 'appointment',
    path: `/appointment/:doctor_id/:specialty_id`,
    component: React.lazy(() => import('views/website/Appointment')),
    authority: [],
  },
];

export default siteRoutes;
